<template>
  <div class="tower-detail">
    <div
      class="tower-detail__title _flex _flex-justify__between _flex-align__center _font-size__heading3"
    >
      <div class="_flex tower-detail__title__left">
        <div>
          <span>Location：</span>
          <span class="_font-weight__600">
            {{ textRender('location') }}
          </span>
        </div>
        <div>
          <span>Sensor Name：</span>
          <span class="_font-weight__600">
            {{ textRender('camera') }}
          </span>
        </div>
        <div>
          <span>Created Date：</span><span class="_font-weight__600">{{ timeRender() }}</span>
        </div>
      </div>

      <div class="tower-detail__title__buttons">
        <el-button
          v-if="['0', '3'].includes(this.source)"
          type="info"
          @click="$router.push({ path: '/data', query: { tabsIndex: 2 } })"
        >
          Go to Multi Angle Camera
        </el-button>
        <el-button type="info" @click="handleBack">Back</el-button>
      </div>
    </div>

    <div class="tower-detail__content _flex">
      <div class="content-left">
        <div class="content-left__img-name">
          <span class="_font-weight__600">Image No: </span>
          {{ textRender('imageNo') }}
        </div>
        <el-image
          fit="cover"
          :src="textRender('imgUrl')"
          :preview-src-list="[textRender('imgUrl')]"
        />
      </div>
      <!-- v-show="isShow" -->
      <div class="content-right">
        <div class="content-right__information _font-weight__600">Defect Information</div>

        <div class="content-right__id _flex _flex-justify__between _flex-align__center">
          <div>
            <span class="_font-size__sub__heading _font-weight__600">Defect</span>
          </div>

          <!-- 这里使用了 el-form 类，使 data.vue 页面的样式可以穿透过来 -->
          <div
            class="_font-size__sub__heading _flex _flex-align__center el-form"
            style="margin-bottom: 0"
          >
            <span class="_font-weight__600">ID: &nbsp;</span>
            <el-select v-model="defectId" @change="handleDefectIdChange">
              <el-option
                v-for="item in defectList ? defectList : []"
                :key="item.defectId"
                :label="item.defectId"
                :value="item.defectId"
              ></el-option>
            </el-select>
          </div>
        </div>

        <div class="content-right__type _font-weight__600">Defect Type</div>

        <el-select
          v-model="formData.defectType"
          class="content-right__tags"
          style="width: 100%; margin-bottom: 10px"
        >
          <el-option
            v-for="item in defectType"
            :key="item.dictLabel"
            :label="item.dictValue"
            :value="item.dictValue"
          ></el-option>
        </el-select>

        <textarea
          class="content-right__description _font-size__sub__heading"
          placeholder="Add Defect Description"
          v-model="formData.description"
        ></textarea>

        <div class="content-right__severity _font-weight__600 _font-size__sub__heading">
          <span>Severity</span>
          <el-select v-model="formData.severity">
            <el-option
              v-for="item in severityOptions"
              :key="item.dictValue"
              :label="item.dictLabel"
              :value="item.dictValue"
            >
            </el-option>
          </el-select>
        </div>

        <!-- <div class="content-right__dimension">
          <span class="_font-weight__600 _font-size__sub__heading">Bounding Box Dimension</span>

          <div class="_flex">
            <div
              class="content-right__dimension__unit _flex _flex-align__center"
              v-for="unit in units"
              :key="unit.label"
            >
              <el-input :placeholder="unit.label" v-model="detail[unit.unit]"></el-input>
              <span class="_font-size__sub__heading">{{ unit.label }} (mm)</span>
            </div>
          </div>
        </div>

        <div class="content-right__date _font-size__sub__heading">
          <div>Created on {{ detail.createTime }}</div>
          <div>Last updates on {{ detail.updateTime }}</div>
        </div> -->

        <div class="content-right__buttons _flex _flex-justify__between">
          <el-button type="default" @click="handleCancel"> Cancel </el-button>
          <el-button type="primary" :disabled="!formData.defectId" @click="handleSave">
            Save
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { parseTime, optionsFun } from '@/utils'
// import { updatePhoto } from '@/api/data'
import { updateAlert, findListByImg } from '@/api/home'
import common from '@/common'

export default {
  props: {
    oldS3Key: String,
    imgDetail: {
      type: Object,
      default: () => {}
    },
    source: {
      type: String,
      // 0 从首页， 1 从 data, 3 从 analysis
      // 3和0唯一不同是，3 点击返回按钮触发 this.$emit('change')
      default: '1'
    },
    showDefectId: String
  },
  data() {
    return {
      formData: {
        defectType: [],
        description: '',
        defectId: '',
        severity: ''
      },
      emptyText: common.EMPTY_TEXT,
      severityOptions: common.constData.severity,
      units: [
        {
          label: 'Height',
          unit: 'height'
        },
        {
          label: 'Width',
          unit: 'width'
        }
      ],

      defectId: '',
      // defect列表
      defectList: [],
      // 缓存的defect
      cacheDefectData: {},
      // 详情数据包含图片信息
      detail: {},

      defectType: optionsFun([
        'Rusting Issue',
        'Delamination',
        'Welding Deterioration',
        'Deformation',
        'Oxidized',
        'Corrosion',
        'Missing bolt'
      ]),
      isShow: false
    }
  },
  watch: {
    oldS3Key: {
      handler(val) {
        if (val) {
          this.getDetail(val)
        }
      },
      immediate: true
    },
    showDefectId(val) {
      if (val) {
        const currentDefect = val
          ? this.defectList.find((item) => item.defectId === val)
          : this.defectList[0]

        this.formData = {
          ...currentDefect
        }

        this.defectId = currentDefect ? currentDefect.defectId : this.defectId
      }
    }
  },
  methods: {
    getDetail(originalPhotoS3Key) {
      this.detail = {}
      this.defectList = []
      this.currentDefect = {}
      this.defectId = ''
      findListByImg(originalPhotoS3Key).then((res) => {
        if (res.result && res.result.length) {
          this.defectList = res.result
          this.detail = { ...res.result[0], ...this.imgDetail }

          const currentDefect = this.showDefectId
            ? res.result.find((item) => item.defectId === this.showDefectId)
            : res.result[0]

          this.formData = {
            ...currentDefect,
            defectType: currentDefect.defectType
          }

          this.defectId = currentDefect.defectId
          this.isShow = true
        } else {
          this.detail = { ...this.imgDetail }
          this.isShow = false
        }
      })
    },
    timeRender() {
      const key = this.detail.photoTime ? 'photoTime' : 'createdTime'
      if (this.detail[key]) {
        return `${parseTime(this.detail[key], 'reg')} ${parseTime(this.detail[key], '{h}:{i}:{s}')}`
      }
      return ''
    },
    textRender(type) {
      let key = ''
      if (this.source === '1') {
        key = {
          location: 'photoLevelAngleDesc',
          imageNo: 'photoIndex',
          imgUrl: 'filePath',
          camera: 'camera'
        }[type]
      } else {
        key = {
          location: 'location',
          imageNo: 'towerPhoto.photoIndex',
          imgUrl: 'towerPhoto.filePath',
          camera: 'towerPhoto.camera'
        }[type]
      }
      const val = this.resolution(this.detail, key)

      switch (type) {
        case 'imageNo':
          return Number(val) ? `${{ 1: '000', 2: '00', 3: '0' }[val.length]}${val}` : val
        default:
      }

      return val
    },
    handleBack() {
      switch (this.source) {
        case '0':
          this.$router.back()
          break
        default:
          this.$emit('change')
      }
    },
    handleCancel() {
      const data = this.defectList.find((item) => item.defectId === this.formData.defectId)

      this.formData = { ...data }
    },
    handleSave() {
      updateAlert({
        ...this.formData
      }).then(() => {
        this.cacheDefectData[this.formData.defectId] = { ...this.formData }
        this.$message.success('save successfully！')
      })
      // }
    },
    handleDefectIdChange(e) {
      if (this.cacheDefectData[e]) {
        this.formData = { ...this.cacheDefectData[e] }
      } else {
        const index = this.defectList.findIndex((item) => item.defectId === e)
        this.formData = { ...this.defectList[index] }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tower-detail {
  width: 100%;
  &__title {
    margin: 0 0 20px;
    width: var(--main-width);

    &__left {
      > div {
        margin-right: 45px;
      }
    }
  }

  &__content {
    padding: 30px;
    background: var(--color-white);
    position: relative;

    .content-left {
      width: calc(var(--main-width) - 60px - 466px);

      &__img-name {
        font-size: 18px;
        margin-bottom: 20px;
      }
      & > .el-image {
        width: 100%;
        height: 580px;
      }
    }

    .content-right {
      width: 466px;
      height: 100%;
      padding: 10px 25px 25px;

      &__information {
        font-size: 16px;
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 3px solid var(--color-info-light-5);
      }

      &__id {
        height: calc(38px + 15px);
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid var(--color-info-light-5);
      }

      &__type {
        height: 36px;
        line-height: 36px;
      }

      ::v-deep &__tags.el-select {
        .el-select__caret.el-icon-arrow-up::before {
          content: '\e78f';
          color: var(--color-black);
        }
        .el-input__icon {
          line-height: 35px;
        }
        .el-input__inner {
          width: 100%;
          height: 35px;
          line-height: 35px;
          border-color: var(--color-info-light-5);
          color: var(--color-black);
          font-weight: 600;
          &::placeholder {
            color: var(--color-black);
          }
        }
      }

      &__description {
        min-width: 100%;
        max-width: 100%;
        min-height: 56px;
        max-height: 56px;
        padding: 5px 10px;
        border-radius: 5px;
        border: 1px solid var(--color-info-light-5);
        color: var(--color-info);
        outline: none;
        margin-bottom: 15px;
      }

      &__severity {
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid var(--color-info-light-5);

        span {
          margin-right: 20px;
        }

        ::v-deep .el-select {
          .el-select__caret.el-icon-arrow-up::before {
            content: '\e78f';
            color: var(--color-black);
          }
          .el-input__icon {
            line-height: 30px;
          }
          .el-input__inner {
            width: 100px;
            height: 30px;
            line-height: 30px;
            border-color: var(--color-info);
            color: var(--color-black);
            font-weight: 600;
            &::placeholder {
              color: var(--color-black);
            }
          }
        }
      }

      &__dimension {
        margin-bottom: 15px;

        > span {
          margin-bottom: 15px;
          display: inline-block;
        }

        &__unit {
          & + .content-right__dimension__unit {
            margin-left: 15px;
          }
          ::v-deep .el-input {
            margin-right: 15px;
            width: 90px;
            .el-input__inner {
              height: 48px;
              line-height: 48px;
              text-align: center;
              color: var(--color-info-light-5);
              border-color: var(--color-info-light-5);

              &::placeholder {
                color: inherit;
              }
            }
          }
        }
      }

      &__date {
        padding-bottom: 15px;
        margin-bottom: 15px;
        color: var(--color-info-light-5);
        border-bottom: 1px solid var(--color-info-light-5);

        > div + div {
          margin-top: 5px;
        }
      }

      &__buttons {
        .el-button--default {
          border: none;
          color: var(--color-primary);
        }
      }
    }
  }

  ::v-deep .el-form {
    .el-input__icon,
    .el-input__inner {
      line-height: 21px;
      height: 21px;
    }
    .el-input__suffix {
      right: 0;
    }
    .el-input__icon {
      width: 18px;
    }

    .el-select {
      width: 160px;
      .el-input__inner {
        height: 21px;
        color: var(--color-black);
        border: none;
        border-bottom: 1px solid var(--color-info);
        border-radius: 0px;
        background: rgba(0, 0, 0, 0);
        font-size: 12px;
        font-weight: 600;
        padding: 0;
        &::placeholder {
          color: var(--color-black);
        }
      }
      .el-select__caret.el-icon-arrow-up::before {
        content: '\e78f';
        color: var(--color-black);
      }
    }
  }
}
</style>
